/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useEffect, useContext, useState } from "react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import productOpts from "./productOpts";
import C_Card from "../../2_components/card/C_Card";
import C_ImageCarousel from "../../2_components/imageCarousel/C_ImageCarousel";
import DropZone from "../../../0_hooks/dropzone";
let prodImg = "/images/fpo/section-product_details/product-placeholder.png";
let selImg = "/images/fpo/section-product_details/select-placeholder.png";

const S_ProductDetails = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  const [currentPanel, setCurrentPanel] = useState("");

  const [currentTab, setCurrentTab] = useState("tab1");
  const [currentTabName, setCurrentTabName] = useState("Specs 1");
  // const [mobileDDActive, setMobileDDActive] = useState(false);

  const [mobileExpand, setMobileExpand] = useState(false);

  const [initialOpen, setInitialOpen] = useState(true);

  const toggleTab = (tab, num) => {
    let tabTxt =
      contentOptions.specifications &&
      contentOptions.specifications[num] &&
      contentOptions.specifications[num].field[contentOptions.currentLang];
    if (currentTab !== tab) {
      setCurrentTab(tab);
      setCurrentTabName(tabTxt);
    }
    // mobileDDActive === true && setMobileDDActive(false);
    mobileExpand === true && setMobileExpand(false);
  };

  useEffect(() => {
    setAppState({
      ...appState,
      currentPath: "/ui/product-details",
      outputName: "ProductDetails",
      headerName: "Product Details",
      tags: null,
      description:
        "The Product Details Section provides information about a specific product, such as its product images, selections, a description, specifications, features, resources, and reviews. It helps the customer make informed decisions when buying.",
      related: null,
      htmlSaved: true,
      currentTheme: "t-white",
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/accordion.component.js",
        "../../js/externalVideo.component.js",
        "../../js/tabs2.layout.js",
        "../../js/imageCarousel.component.js",
        "productDetails.section.js",
      ],
      specs: [
        [
          "Image Sizes",
          [
            "*All 4 sizes required",
            "Thumbnail: 56x56",
            "Small: 512x408",
            "Full/Medium: 416x416",
            "Zoom Large: 1304x1304",
          ],
        ],
        ["Image Formats", ["MP4s, jpg, png formats. Png is used for photo icons."]],
        [
          "Feature Icons (mobile, tablet, desktop)",
          [
            "*1 image required",
            "Small, Medium, Large: Width and Height: any as long as the ratio is 1:1",
          ],
        ],
        ["Image Type", ["SVG"]],
        [
          "Video (mobile, tablet, desktop)",
          [
            "*1 video optional",
            "Small, Medium, Large: Current is Width: 416px, Height: 400px, any as long as the ratio is 1:1 and below current size",
          ],
        ],
        ["Copy", ["The copy appears as a paragraph beneath the title. No Character limit."]],
        ["Title", ["Defaults to an <h2> and should only be text."]],
        ["Features", ["String text, No Character limit."]],
        ["CTA", ["String text for CTA button"]],
      ],
      cis: {
        cmsDoc: "",
        comments: [
          ["Product Image Size", ["idk what the size iss"]],
          ["Product Video Size", ["416px x 400px"]],
        ],
      },
    });
    setContentOptions(productOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "883",
      width: window.innerWidth - 24,
    });
  }, []);

  function handleToggle(accordion) {
    if (initialOpen == true) setInitialOpen(false);
    accordion == currentPanel ? setCurrentPanel("") : setCurrentPanel(accordion);
  }

  if (!contentOptions || Object.keys(contentOptions) < 1)
    return "...Loading Product Details Section";
  return (
    <>
      <section
        className={`s-productDetails${contentOptions.enablestickybar && contentOptions.enablestickybar.checkbox ? " s-productDetails--stickyBarActive" : ""} ${appState.currentTheme}`}
      >
        <div className="l-inner">
          <div className="l-row s-productDetails__ecommerce">
            <div className="l-row__col l-2/3@lg">
              <h1 className="s-productDetails__ecommerce__headline--mobile">
                {contentOptions.productname && contentOptions.productname.field}
              </h1>
              <C_ImageCarousel />
            </div>
            <div className="l-row__col l-1/3@lg">
              <img src={selImg} />
              {/* Classes for product selections example */}
              {/* <h1 className="s-productDetails__ecommerce__headline">Canvas Go! Plus microSD Memory Card</h1>
              <form className="s-productDetails__selections">
                <fieldset>
                  <div className="s-productDetails__ecommerce__selections">
                    <div className="s-productDetails__ecommerce__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                      />
                      <label for="id0">Small</label>
                    </div>
                    <div className="s-productDetails__ecommerce__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                      />
                      <label for="id1">Medium</label>
                    </div>
                    <div className="s-productDetails__ecommerce__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                      />
                      <label for="id2">Large</label>
                    </div>
                    <div className="s-productDetails__ecommerce__selections__item">
                      <input
                        className="radio-button"
                        type="radio"
                      />
                      <label for="id3">Extra Large</label>
                    </div>
                  </div>
                </fieldset>{" "}
                <ul className="s-productDetails__ecommerce__features">
                  <li>Lorem ipsum dolor</li>
                  <li>Consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor</li>
                  <li>Consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor</li>
                  <li>Consectetur: sed do eiusmod.</li>
                </ul>
                <div className="s-productDetails__ecommerce__price">
                  <span className="s-productDetails__ecommerce__price__cost">
                    <span className="s-productDetails__ecommerce__price__cost__dollar">$10.00</span>
                  </span>
                  <span className="s-productDetails__ecommerce__price__discount">$1.00</span>
                  <span className="s-productDetails__ecommerce__price__save">lorem 10%</span>
                </div>
                <div className="s-productDetails__ecommerce__default">
                  <label className="f-quantity__label" for="quant2">
                    Quantity
                  </label>
                  <div className="f-quantity js-quantity" id="jsQuantity0">
                    <div className="f-quantity__counter">
                      <button
                        className="f-quantity__counter__btn js-quantity__btn false"
                        aria-label="Decrease Quantity"
                        tabindex="-1"
                      >
                        <span className="f-quantity__counter__btn__inner">
                          <svg viewBox="0 0 16 2">
                            <title>Minus Icon</title>
                            <path d="M0 0h16v2H0z"></path>
                          </svg>
                        </span>
                      </button>
                      <input
                        className="f-quantity__counter__input"
                        type="text"
                        maxlength="4"
                        id="quant2"
                        name="fQuantity0"
                        value="1"
                      />
                      <button
                        className="f-quantity__counter__btn js-quantity__btn"
                        aria-label="Increase Quantity"
                        data-add="true"
                      >
                        <span className="f-quantity__counter__btn__inner">
                          <svg viewBox="0 0 16 16">
                            <title>Plus Icon</title>
                            <path d="M0 7h16v2H0z"></path>
                            <path d="M7 0h2v16H7z"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <a className="e-btn" href="#cart" title="Add Item to Cart" role="button">
                    <svg viewBox="0 0 25 25">
                      <path></path>
                    </svg>
                    <span>Add To Cart</span>
                  </a>
                </div>
              </form> 
              */}
              {/* Classes for ecommerce links example */}
              {/* <div className="s-productDetails__ecommerce__links">
              <ul>
                <li>Share</li> (use social share component c-socials for dropdown)
                <li>Datasheet</li>
                <li>Product Finder</li>
                <li>Need some help? Ask an Expert</li>
              </ul>
             </div> */}
            </div>
          </div>
          <div className="s-productDetails__stickycta" aria-hidden="true">
            <div className="s-productDetails__stickycta__product">
              <div className="s-productDetails__stickycta__product__img">
                <img
                  loading="lazy"
                  src={
                    contentOptions.stickyimg && contentOptions.stickyimg.selected == "Image 1"
                      ? "https://media.kingston.com/kingston/product/ktc-product-usb-ikvp50-8gb-1-tn.jpg"
                      : "https://media.kingston.com/kingston/product/ktc-product-usb-ironkey-s1000-basic-iks1000b-1-tn.jpg"
                  }
                  alt="FURY Beast DDR4 RGB"
                  title="FURY Beast DDR4 RGB"
                />
              </div>
              <div className="s-productDetails__stickycta__product__name">
                {contentOptions.productname && contentOptions.productname.field}
              </div>
            </div>
            <div className="s-productDetails__stickycta__cta">
              {contentOptions.priceoptions && contentOptions.priceoptions.selected != "None" ? (
                <>
                  {contentOptions.productprice ? (
                    <div className="s-productDetails__stickycta__cta__price">
                      {contentOptions.priceoptions &&
                      contentOptions.priceoptions.selected == "Range" ? (
                        <span>From</span>
                      ) : (
                        ""
                      )}

                      {contentOptions.priceoptions &&
                      contentOptions.priceoptions.selected == "Discount" ? (
                        <span className="u-txt-strike s-productDetails__stickycta__cta__price__discount">
                          {contentOptions.productdiscount && contentOptions.productdiscount.field}
                        </span>
                      ) : (
                        ""
                      )}

                      <span className="s-productDetails__stickycta__cta__price__value">
                        {contentOptions.productprice && contentOptions.productprice.field}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              <button className="e-btn s-productDetails__stickycta__cta__btn" tabIndex={-1}>
                Buy
              </button>
            </div>
          </div>
          <div className="l-row l-row__col s-productDetails__description">
            <p className="s-productDetails__description__text">
              {contentOptions.description &&
                contentOptions.description.field[contentOptions.currentLang]}
            </p>
            <p className="s-productDetails__description__text">
              {contentOptions.description &&
                contentOptions.description.field[contentOptions.currentLang]}
            </p>
          </div>
          <a className="s-productDetails__description__readMore" role="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path d="M6 0c.552 0 1 .456 1 1.002V5h3.998c.514 0 .937.383.995.883L12 6c0 .552-.456 1-1.002 1l-3.999-.001L7 10.998a1 1 0 0 1-.883.995L6 12c-.552 0-1-.456-1-1.002l-.001-3.999L1.002 7a.999.999 0 1 1 0-2L5 4.999V1.002A1 1 0 0 1 6 0" />
            </svg>
            Read more
          </a>
          <a className="s-productDetails__description__readLess" role="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path d="M0 6c0-.552.456-1 1.002-1h9.996A1 1 0 0 1 12 6c0 .552-.456 1-1.002 1H1.002A1 1 0 0 1 0 6" />
            </svg>
            Read Less
          </a>
          <div className="l-row s-productDetails__additional">
            <div className="l-row__col">
              <div className="c-accordion c-accordion--noSidePadding" data-multiselect="false">
                {contentOptions.numspecs && contentOptions.numspecs.selected > 0 ? (
                  <>
                    <span
                      className={`c-accordion__tab${initialOpen == true ? " c-accordion__tab--active" : ""}${
                        currentPanel == "accPanel-1" ? " c-accordion__tab--active" : ""
                      }`}
                      id="accTab-1"
                    >
                      <button
                        aria-controls="accPanel-1"
                        aria-expanded="false"
                        onClick={() => handleToggle("accPanel-1")}
                      >
                        <h2 className="s-productDetails__additional__title" >
                          {contentOptions.specifications &&
                            contentOptions.specifications[0] &&
                            contentOptions.specifications[0].field[contentOptions.currentLang]}
                        </h2>
                        <svg
                          className="c-accordion__tab__icon"
                          viewBox="0 0 14 8"
                          aria-hidden={true}
                        >
                          <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                        </svg>
                      </button>
                    </span>
                    <div className="c-accordion__panel" id="accPanel-1" aria-labelledby="accTab-1">
                      <div className="c-accordion__panel__content">
                        <div className="s-productDetails__additional__specifications">
                          {contentOptions.numspecs.selected == 1 ? (
                            <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                              <div className="c-table__main">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Capacities
                                        <sup
                                          className="u-txt-footnote"
                                          data-original="{{Footnote.N47164}}"
                                        >
                                          2
                                        </sup>
                                      </td>
                                      <td>64GB, 128GB, 256GB, 512GB</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Speed
                                        <sup className="u-txt-footnote">1</sup>
                                      </td>
                                      <td>
                                        USB 3.2 Gen 1 speeds
                                        <br />
                                        220MB/s read, 100MB/s write
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dimensions </td>
                                      <td>39mm x 12.2mm x 4.6mm</td>
                                    </tr>
                                    <tr>
                                      <td>Weight </td>
                                      <td>5g</td>
                                    </tr>
                                    <tr>
                                      <td>Operating temperature </td>
                                      <td>0°C~60°C</td>
                                    </tr>
                                    <tr>
                                      <td>Storage temperature </td>
                                      <td>-20°C~85°C</td>
                                    </tr>
                                    <tr>
                                      <td>Warranty/support</td>
                                      <td>
                                        5-year warranty with free technical support<sub>low</sub>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Compatible with</td>
                                      <td>
                                        Windows
                                        <sup className="u-txt-copyright">&copy;</sup>
                                        <sup className="u-txt-registered">&reg;</sup>
                                        <sup className="u-txt-trademark">&trade;</sup>
                                        11, 10, MacOS (v.10.15.x +), Linux (v. 4.4.x +), Chrome OS
                                        <sup className="u-txt-trademark">&trade;</sup>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : contentOptions.numspecs.selected >= 2 ? (
                            <div className="l-tabView " data-menutype="tablet">
                              <button
                                className="l-tabView__mobile"
                                aria-expanded={mobileExpand}
                                onClick={() => setMobileExpand(!mobileExpand)}
                              >
                                <span>{currentTabName}</span>
                                <svg
                                  viewBox="0 0 14 8"
                                  className="l-tabView__mobile__arrow"
                                  aria-hidden="true"
                                >
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </button>
                              <ul className="l-tabView__tabs" role="tablist">
                                <li
                                  className={`l-tabView__tabs__tab  ${
                                    currentTab === "tab1" ? "l-tabView__tabs__tab--active" : ""
                                  }`}
                                  role="tab"
                                  aria-controls="tabContent0_0"
                                  id="tab0_0"
                                  aria-selected={currentTab === "tab1" ? "true" : "false"}
                                  tabIndex="0"
                                  onClick={() => toggleTab("tab1", 1)}
                                  onKeyDown={() => toggleTab("tab1", 1)}
                                >
                                  {contentOptions.specifications &&
                                    contentOptions.specifications[1] &&
                                    contentOptions.specifications[1].field[
                                      contentOptions.currentLang
                                    ]}
                                </li>
                                <li
                                  className={`l-tabView__tabs__tab  ${
                                    currentTab === "tab2" ? "l-tabView__tabs__tab--active" : ""
                                  }`}
                                  role="tab"
                                  aria-controls="tabContent0_1"
                                  id="tab0_1"
                                  aria-selected={currentTab === "tab2" ? "true" : "false"}
                                  tabIndex="-1"
                                  onClick={() => toggleTab("tab2", 2)}
                                  onKeyDown={() => toggleTab("tab2", 2)}
                                >
                                  {contentOptions.specifications &&
                                    contentOptions.specifications[2] &&
                                    contentOptions.specifications[2].field[
                                      contentOptions.currentLang
                                    ]}
                                </li>
                                {contentOptions.numspecs.selected >= 3 ? (
                                  <li
                                    className={`l-tabView__tabs__tab  ${
                                      currentTab === "tab3" ? "l-tabView__tabs__tab--active" : ""
                                    }`}
                                    role="tab"
                                    aria-controls="tabContent0_2"
                                    id="tab0_2"
                                    aria-selected={currentTab === "tab3" ? "true" : "false"}
                                    tabIndex="-1"
                                    onClick={() => toggleTab("tab3", 3)}
                                    onKeyDown={() => toggleTab("tab3", 3)}
                                  >
                                    {contentOptions.specifications &&
                                      contentOptions.specifications[3] &&
                                      contentOptions.specifications[3].field[
                                        contentOptions.currentLang
                                      ]}
                                  </li>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs.selected >= 4 ? (
                                  <li
                                    className={`l-tabView__tabs__tab  ${
                                      currentTab === "tab4" ? "l-tabView__tabs__tab--active" : ""
                                    }`}
                                    role="tab"
                                    aria-controls="tabContent0_3"
                                    id="tab0_3"
                                    aria-selected={currentTab === "tab4" ? "true" : "false"}
                                    tabIndex="-1"
                                    onClick={() => toggleTab("tab4", 4)}
                                    onKeyDown={() => toggleTab("tab4", 4)}
                                  >
                                    {contentOptions.specifications &&
                                      contentOptions.specifications[4] &&
                                      contentOptions.specifications[4].field[
                                        contentOptions.currentLang
                                      ]}
                                  </li>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs.selected >= 5 ? (
                                  <li
                                    className={`l-tabView__tabs__tab  ${
                                      currentTab === "tab5" ? "l-tabView__tabs__tab--active" : ""
                                    }`}
                                    role="tab"
                                    aria-controls="tabContent0_4"
                                    id="tab0_4"
                                    aria-selected={currentTab === "tab5" ? "true" : "false"}
                                    tabIndex="-1"
                                    onClick={() => toggleTab("tab5", 5)}
                                    onKeyDown={() => toggleTab("tab5", 5)}
                                  >
                                    {contentOptions.specifications &&
                                      contentOptions.specifications[5] &&
                                      contentOptions.specifications[5].field[
                                        contentOptions.currentLang
                                      ]}
                                  </li>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs.selected == 6 ? (
                                  <li
                                    className={`l-tabView__tabs__tab  ${
                                      currentTab === "tab6" ? "l-tabView__tabs__tab--active" : ""
                                    }`}
                                    role="tab"
                                    aria-controls="tabContent0_5"
                                    id="tab0_5"
                                    aria-selected={currentTab === "tab6" ? "true" : "false"}
                                    tabIndex="-1"
                                    onClick={() => toggleTab("tab6", 6)}
                                    onKeyDown={() => toggleTab("tab6", 6)}
                                  >
                                    {contentOptions.specifications &&
                                      contentOptions.specifications[6] &&
                                      contentOptions.specifications[6].field[
                                        contentOptions.currentLang
                                      ]}
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                              <div className="l-tabView__panels">
                                <div
                                  className={`l-tabView__panels__panel ${
                                    currentTab === "tab1" ? "l-tabView__panels__panel--active" : ""
                                  }`}
                                  role="tabpanel"
                                  aria-labelledby="tab0_0"
                                  id="tabContent0_0"
                                >
                                  <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                    <div className="c-table__main">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              Capacities
                                              <sup
                                                className="u-txt-footnote"
                                                data-original="{{Footnote.N47164}}"
                                              >
                                                2
                                              </sup>
                                            </td>
                                            <td>64GB, 128GB, 256GB, 512GB</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Speed
                                              <sup className="u-txt-footnote">1</sup>
                                            </td>
                                            <td>
                                              USB 3.2 Gen 1 speeds
                                              <br />
                                              220MB/s read, 100MB/s write
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Dimensions </td>
                                            <td>39mm x 12.2mm x 4.6mm</td>
                                          </tr>
                                          <tr>
                                            <td>Weight </td>
                                            <td>5g</td>
                                          </tr>
                                          <tr>
                                            <td>Operating temperature </td>
                                            <td>0°C~60°C</td>
                                          </tr>
                                          <tr>
                                            <td>Storage temperature </td>
                                            <td>-20°C~85°C</td>
                                          </tr>
                                          <tr>
                                            <td>Warranty/support</td>
                                            <td>
                                              5-year warranty with free technical support
                                              <sub>low</sub>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Compatible with</td>
                                            <td>
                                              Windows
                                              <sup className="u-txt-copyright">&copy;</sup>
                                              <sup className="u-txt-registered">&reg;</sup>
                                              <sup className="u-txt-trademark">&trade;</sup>
                                              11, 10, MacOS (v.10.15.x +), Linux (v. 4.4.x +),
                                              Chrome OS
                                              <sup className="u-txt-trademark">&trade;</sup>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`l-tabView__panels__panel ${
                                    currentTab === "tab2" ? "l-tabView__panels__panel--active" : ""
                                  }`}
                                  role="tabpanel"
                                  aria-labelledby="tab0_1"
                                  id="tabContent0_1"
                                >
                                  <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                    <div className="c-table__main">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              Capacities<sup className="u-txt-footnote">2</sup>
                                            </td>
                                            <td>32GB, 64GB, 128GB, 256GB</td>
                                          </tr>
                                          <tr>
                                            <td>Standard/Class</td>
                                            <td>Class 10, UHS-II, U3, V90</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Performance<sup className="u-txt-footnote">1</sup>
                                            </td>
                                            <td>300/260MB/s read/write</td>
                                          </tr>
                                          <tr>
                                            <td>Dimensions </td>
                                            <td>24mm x 32mm x 2.1mm (SD)</td>
                                          </tr>
                                          <tr>
                                            <td>Format</td>
                                            <td>
                                              FAT32 (32GB)
                                              <br />
                                              exFAT (64GB-256GB)
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Operating temperature </td>
                                            <td>-25°C~85°C</td>
                                          </tr>
                                          <tr>
                                            <td>Storage temperature </td>
                                            <td>-40°C~85°C</td>
                                          </tr>
                                          <tr>
                                            <td>Voltage</td>
                                            <td>3.3V</td>
                                          </tr>
                                          <tr>
                                            <td>Warranty/support</td>
                                            <td>Lifetime </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                {contentOptions.numspecs &&
                                contentOptions.numspecs.selected >= 3 ? (
                                  <div
                                    className={`l-tabView__panels__panel ${
                                      currentTab === "tab3"
                                        ? "l-tabView__panels__panel--active"
                                        : ""
                                    }`}
                                    role="tabpanel"
                                    aria-labelledby="tab0_2"
                                    id="tabContent0_2"
                                  >
                                    <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                      <div className="c-table__main">
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>Interface</td>
                                              <td>USB 3.2 Gen 1</td>
                                            </tr>
                                            <tr>
                                              <td>Connector</td>
                                              <td>USB-A</td>
                                            </tr>
                                            <tr>
                                              <td>Standard/Class</td>
                                              <td>UHS-II</td>
                                            </tr>
                                            <tr>
                                              <td>Dimensions</td>
                                              <td>51.8mm x 33.5mm x 9mm</td>
                                            </tr>
                                            <tr>
                                              <td>Weight</td>
                                              <td>11g</td>
                                            </tr>
                                            <tr>
                                              <td>Operating temperature </td>
                                              <td>0°C~60°C</td>
                                            </tr>
                                            <tr>
                                              <td>Storage temperature </td>
                                              <td>-20°C~70°C</td>
                                            </tr>
                                            <tr>
                                              <td>Warranty/support</td>
                                              <td>2-year</td>
                                            </tr>
                                            <tr>
                                              <td>Compatible with</td>
                                              <td>
                                                Windows<sup className="u-txt-registered">&reg;</sup>{" "}
                                                10, Windows 8.1, Windows 8, Mac OS X v. 10.10.x+,
                                                Linux v.2.6.x+, Chrome OS
                                                <sup className="u-txt-trademark">&trade;</sup>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs &&
                                contentOptions.numspecs.selected >= 4 ? (
                                  <div
                                    className={`l-tabView__panels__panel ${
                                      currentTab === "tab4"
                                        ? "l-tabView__panels__panel--active"
                                        : ""
                                    }`}
                                    role="tabpanel"
                                    aria-labelledby="tab0_3"
                                    id="tabContent0_3"
                                  >
                                    <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                      <div className="c-table__main">
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>Form Factor</td>
                                              <td>M.2, 22mm x 80mm (2280)</td>
                                            </tr>
                                            <tr>
                                              <td>Interface</td>
                                              <td>PCIe NVMe Gen3 x4 </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Capacities
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47164}}"
                                                >
                                                  1
                                                </sup>
                                              </td>
                                              <td>240GB, 480GB</td>
                                            </tr>
                                            <tr>
                                              <td>NAND</td>
                                              <td>3D TLC</td>
                                            </tr>
                                            <tr>
                                              <td>Self-Encrypting Drive (SED)</td>
                                              <td>AES 256-bit Encryption</td>
                                            </tr>
                                            <tr>
                                              <td>Sequential Read/Write</td>
                                              <td>
                                                240GB – 2,200MBs/290MBs
                                                <br />
                                                480GB – 3,200MBs/565MBs
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Steady-State 4k Read/Write
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47723}}"
                                                >
                                                  2
                                                </sup>
                                              </td>
                                              <td>
                                                240GB – 111.000/12.000 IOPS
                                                <br />
                                                480GB – 205.000/20.000 IOPS
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Latency Read (Avg)</td>
                                              <td>161µs</td>
                                            </tr>
                                            <tr>
                                              <td>Latency Write (Avg)</td>
                                              <td>75µs </td>
                                            </tr>
                                            <tr>
                                              <td>Power Loss Protection (Power Caps)</td>
                                              <td>Yes</td>
                                            </tr>
                                            <tr>
                                              <td>SMART Health Monitoring and Telemetry </td>
                                              <td>
                                                SMART, Telemetry and other Enterprise Class
                                                Diagnostic capabilities
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Endurance</td>
                                              <td>
                                                240GB — 248TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>{" "}
                                                <br />
                                                480GB — 475TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Power Consumption</td>
                                              <td>
                                                240GB: Idle: 1.82W Average Read: 1.71W Average
                                                Write: 3.16W Max Read: 1.81W Max Write: 3.56W
                                                <br />
                                                480GB: Idle: 1.90W Average Read: 1.74W Average
                                                Write: 4.88W Max Read: 1.81W Max Write: 5.47W
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Storage temperature</td>
                                              <td>-40°C ~ 85°C</td>
                                            </tr>
                                            <tr>
                                              <td>Operating temperature</td>
                                              <td>0°C ~ 70°C</td>
                                            </tr>
                                            <tr>
                                              <td>Dimensions</td>
                                              <td>80mm x 22mm x 3.8mm</td>
                                            </tr>
                                            <tr>
                                              <td>Weight</td>
                                              <td>
                                                240GB – 8g <br />
                                                480GB – 9g{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Vibration operating</td>
                                              <td>2.17G Peak (7–800Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>Vibration non-operating</td>
                                              <td>20G Peak (10–2000Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>MTBF</td>
                                              <td>2 million hours</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Warranty/support
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N48982}}"
                                                >
                                                  4
                                                </sup>
                                              </td>
                                              <td>
                                                Limited 5-year warranty with free technical support
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs &&
                                contentOptions.numspecs.selected >= 5 ? (
                                  <div
                                    className={`l-tabView__panels__panel ${
                                      currentTab === "tab5"
                                        ? "l-tabView__panels__panel--active"
                                        : ""
                                    }`}
                                    role="tabpanel"
                                    aria-labelledby="tab0_4"
                                    id="tabContent0_4"
                                  >
                                    <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                      <div className="c-table__main">
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>Form Factor</td>
                                              <td>M.2, 22mm x 80mm (2280)</td>
                                            </tr>
                                            <tr>
                                              <td>Interface</td>
                                              <td>PCIe NVMe Gen3 x4 </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Capacities
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47164}}"
                                                >
                                                  1
                                                </sup>
                                              </td>
                                              <td>240GB, 480GB</td>
                                            </tr>
                                            <tr>
                                              <td>NAND</td>
                                              <td>3D TLC</td>
                                            </tr>
                                            <tr>
                                              <td>Self-Encrypting Drive (SED)</td>
                                              <td>AES 256-bit Encryption</td>
                                            </tr>
                                            <tr>
                                              <td>Sequential Read/Write</td>
                                              <td>
                                                240GB – 2,200MBs/290MBs
                                                <br />
                                                480GB – 3,200MBs/565MBs
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Steady-State 4k Read/Write
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47723}}"
                                                >
                                                  2
                                                </sup>
                                              </td>
                                              <td>
                                                240GB – 111.000/12.000 IOPS
                                                <br />
                                                480GB – 205.000/20.000 IOPS
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Latency Read (Avg)</td>
                                              <td>161µs</td>
                                            </tr>
                                            <tr>
                                              <td>Latency Write (Avg)</td>
                                              <td>75µs </td>
                                            </tr>
                                            <tr>
                                              <td>Power Loss Protection (Power Caps)</td>
                                              <td>Yes</td>
                                            </tr>
                                            <tr>
                                              <td>SMART Health Monitoring and Telemetry </td>
                                              <td>
                                                SMART, Telemetry and other Enterprise Class
                                                Diagnostic capabilities
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Endurance</td>
                                              <td>
                                                240GB — 248TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>{" "}
                                                <br />
                                                480GB — 475TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Power Consumption</td>
                                              <td>
                                                240GB: Idle: 1.82W Average Read: 1.71W Average
                                                Write: 3.16W Max Read: 1.81W Max Write: 3.56W
                                                <br />
                                                480GB: Idle: 1.90W Average Read: 1.74W Average
                                                Write: 4.88W Max Read: 1.81W Max Write: 5.47W
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Storage temperature</td>
                                              <td>-40°C ~ 85°C</td>
                                            </tr>
                                            <tr>
                                              <td>Operating temperature</td>
                                              <td>0°C ~ 70°C</td>
                                            </tr>
                                            <tr>
                                              <td>Dimensions</td>
                                              <td>80mm x 22mm x 3.8mm</td>
                                            </tr>
                                            <tr>
                                              <td>Weight</td>
                                              <td>
                                                240GB – 8g <br />
                                                480GB – 9g{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Vibration operating</td>
                                              <td>2.17G Peak (7–800Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>Vibration non-operating</td>
                                              <td>20G Peak (10–2000Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>MTBF</td>
                                              <td>2 million hours</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Warranty/support
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N48982}}"
                                                >
                                                  4
                                                </sup>
                                              </td>
                                              <td>
                                                Limited 5-year warranty with free technical support
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {contentOptions.numspecs &&
                                contentOptions.numspecs.selected == 6 ? (
                                  <div
                                    className={`l-tabView__panels__panel ${
                                      currentTab === "tab6"
                                        ? "l-tabView__panels__panel--active"
                                        : ""
                                    }`}
                                    role="tabpanel"
                                    aria-labelledby="tab0_5"
                                    id="tabContent0_5"
                                  >
                                    <div className="c-table c-table--striped c-table--sideKeys c-table--2col">
                                      <div className="c-table__main">
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>Form Factor</td>
                                              <td>M.2, 22mm x 80mm (2280)</td>
                                            </tr>
                                            <tr>
                                              <td>Interface</td>
                                              <td>PCIe NVMe Gen3 x4 </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Capacities
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47164}}"
                                                >
                                                  1
                                                </sup>
                                              </td>
                                              <td>240GB, 480GB</td>
                                            </tr>
                                            <tr>
                                              <td>NAND</td>
                                              <td>3D TLC</td>
                                            </tr>
                                            <tr>
                                              <td>Self-Encrypting Drive (SED)</td>
                                              <td>AES 256-bit Encryption</td>
                                            </tr>
                                            <tr>
                                              <td>Sequential Read/Write</td>
                                              <td>
                                                240GB – 2,200MBs/290MBs
                                                <br />
                                                480GB – 3,200MBs/565MBs
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Steady-State 4k Read/Write
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N47723}}"
                                                >
                                                  2
                                                </sup>
                                              </td>
                                              <td>
                                                240GB – 111.000/12.000 IOPS
                                                <br />
                                                480GB – 205.000/20.000 IOPS
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Latency Read (Avg)</td>
                                              <td>161µs</td>
                                            </tr>
                                            <tr>
                                              <td>Latency Write (Avg)</td>
                                              <td>75µs </td>
                                            </tr>
                                            <tr>
                                              <td>Power Loss Protection (Power Caps)</td>
                                              <td>Yes</td>
                                            </tr>
                                            <tr>
                                              <td>SMART Health Monitoring and Telemetry </td>
                                              <td>
                                                SMART, Telemetry and other Enterprise Class
                                                Diagnostic capabilities
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Endurance</td>
                                              <td>
                                                240GB — 248TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>{" "}
                                                <br />
                                                480GB — 475TBW (0.5 DWPD/5yrs)
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N63869}}"
                                                >
                                                  3
                                                </sup>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Power Consumption</td>
                                              <td>
                                                240GB: Idle: 1.82W Average Read: 1.71W Average
                                                Write: 3.16W Max Read: 1.81W Max Write: 3.56W
                                                <br />
                                                480GB: Idle: 1.90W Average Read: 1.74W Average
                                                Write: 4.88W Max Read: 1.81W Max Write: 5.47W
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Storage temperature</td>
                                              <td>-40°C ~ 85°C</td>
                                            </tr>
                                            <tr>
                                              <td>Operating temperature</td>
                                              <td>0°C ~ 70°C</td>
                                            </tr>
                                            <tr>
                                              <td>Dimensions</td>
                                              <td>80mm x 22mm x 3.8mm</td>
                                            </tr>
                                            <tr>
                                              <td>Weight</td>
                                              <td>
                                                240GB – 8g <br />
                                                480GB – 9g{" "}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Vibration operating</td>
                                              <td>2.17G Peak (7–800Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>Vibration non-operating</td>
                                              <td>20G Peak (10–2000Hz)</td>
                                            </tr>
                                            <tr>
                                              <td>MTBF</td>
                                              <td>2 million hours</td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Warranty/support
                                                <sup
                                                  className="u-txt-footnote"
                                                  data-original="{{Footnote.N48982}}"
                                                >
                                                  4
                                                </sup>
                                              </td>
                                              <td>
                                                Limited 5-year warranty with free technical support
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {contentOptions.numfeatures && contentOptions.numfeatures.selected > 0 ? (
                  <>
                    <span
                      className={`c-accordion__tab${
                        currentPanel == "accPanel-2" ? " c-accordion__tab--active" : ""
                      }`}
                      id="accTab-2"
                    >
                      <button
                        aria-controls="accPanel-2"
                        aria-expanded="false"
                        onClick={() => handleToggle("accPanel-2")}
                      >
                        <h2 className="s-productDetails__additional__title">
                          {contentOptions.features &&
                            contentOptions.features[0] &&
                            contentOptions.features[0].field[contentOptions.currentLang]}
                        </h2>
                        <svg
                          className="c-accordion__tab__icon"
                          viewBox="0 0 14 8"
                          aria-hidden={true}
                        >
                          <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                        </svg>
                      </button>
                    </span>
                    <div
                      className="c-accordion__panel c-accordion__panel s-productDetails__additional__features"
                      id="accPanel-2"
                      aria-labelledby="accTab-2"
                    >
                      <div className="c-accordion__panel__content">
                        <ul className="c-iconFeature u-list-unstyled c-iconFeature--3col">
                          <li className="c-iconFeature__item">
                            <svg className="c-iconFeature__item__icon" aria-hidden="true">
                              <use
                                xmlns="http://www.w3.org/1999/xlink"
                                xlinkHref="../images/icons-map.svg#iphone-ipad"
                              />
                            </svg>
                            <div className="c-iconFeature__item__content">
                              <p className="c-iconFeature__item__content__copy">
                                {contentOptions.features &&
                                  contentOptions.features[1] &&
                                  contentOptions.features[1].field[contentOptions.currentLang]}
                              </p>
                            </div>
                          </li>
                          {contentOptions.numfeatures.selected >= 2 ? (
                            <li className="c-iconFeature__item">
                              <svg className="c-iconFeature__item__icon" aria-hidden="true">
                                <use
                                  xmlns="http://www.w3.org/1999/xlink"
                                  xlinkHref="../images/icons-map.svg#iphone-ipad"
                                />
                              </svg>
                              <div className="c-iconFeature__item__content">
                                <p className="c-iconFeature__item__content__copy">Lorem ipsum.</p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numfeatures.selected >= 3 ? (
                            <li className="c-iconFeature__item">
                              <svg className="c-iconFeature__item__icon" aria-hidden="true">
                                <use
                                  xmlns="http://www.w3.org/1999/xlink"
                                  xlinkHref="../images/icons-map.svg#iphone-ipad"
                                />
                              </svg>
                              <div className="c-iconFeature__item__content">
                                <p className="c-iconFeature__item__content__copy">
                                  Lorem ipsum dolor.
                                </p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numfeatures.selected >= 4 ? (
                            <li className="c-iconFeature__item">
                              <svg className="c-iconFeature__item__icon" aria-hidden="true">
                                <use
                                  xmlns="http://www.w3.org/1999/xlink"
                                  xlinkHref="../images/icons-map.svg#iphone-ipad"
                                />
                              </svg>
                              <div className="c-iconFeature__item__content">
                                <p className="c-iconFeature__item__content__copy">
                                  Lorem ipsum dolor.
                                </p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numfeatures.selected >= 5 ? (
                            <li className="c-iconFeature__item">
                              <svg className="c-iconFeature__item__icon" aria-hidden="true">
                                <use
                                  xmlns="http://www.w3.org/1999/xlink"
                                  xlinkHref="../images/icons-map.svg#iphone-ipad"
                                />
                              </svg>
                              <div className="c-iconFeature__item__content">
                                <p className="c-iconFeature__item__content__copy">Lorem ipsum.</p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numfeatures.selected == 6 ? (
                            <li className="c-iconFeature__item">
                              <svg className="c-iconFeature__item__icon" aria-hidden="true">
                                <use
                                  xmlns="http://www.w3.org/1999/xlink"
                                  xlinkHref="../images/icons-map.svg#iphone-ipad"
                                />
                              </svg>
                              <div className="c-iconFeature__item__content">
                                <p className="c-iconFeature__item__content__copy">
                                  Lorem ipsum dolor sit.
                                </p>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {contentOptions.numresources && contentOptions.numresources.selected > 0 ? (
                  <>
                    <span
                      className={`c-accordion__tab${
                        currentPanel == "accPanel-3" ? " c-accordion__tab--active" : ""
                      }`}
                      id="accTab-2"
                    >
                      <button
                        aria-controls="accPanel-3"
                        aria-expanded="false"
                        onClick={() => handleToggle("accPanel-3")}
                      >
                        <h2 className="s-productDetails__additional__title">
                          {contentOptions.resources &&
                            contentOptions.resources[0] &&
                            contentOptions.resources[0].field[contentOptions.currentLang]}
                        </h2>
                        <svg
                          className="c-accordion__tab__icon"
                          viewBox="0 0 14 8"
                          aria-hidden={true}
                        >
                          <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                        </svg>
                      </button>
                    </span>
                    <div
                      className="c-accordion__panel s-productDetails__additional__resources"
                      id="accPanel-2"
                      aria-labelledby="accTab-3"
                    >
                      <div className="c-accordion__panel__content">
                        <ul className="u-list-unstyled s-productDetails__additional__resources__list">
                          <li>
                            <a
                              href="#"
                              className="s-productDetails__additional__resources__list__link"
                            >
                              {contentOptions.resources &&
                                contentOptions.resources[1] &&
                                contentOptions.resources[1].field[contentOptions.currentLang]}
                              <svg viewBox="0 0 14 8" aria-hidden={true}>
                                <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                              </svg>
                            </a>
                          </li>
                          {contentOptions.numresources.selected >= 2 ? (
                            <li>
                              <a
                                href="#"
                                className="s-productDetails__additional__resources__list__link"
                              >
                                {contentOptions.resources &&
                                  contentOptions.resources[2] &&
                                  contentOptions.resources[2].field[contentOptions.currentLang]}
                                <svg viewBox="0 0 14 8" aria-hidden={true}>
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numresources.selected >= 3 ? (
                            <li>
                              <a
                                href="#"
                                className="s-productDetails__additional__resources__list__link"
                              >
                                {contentOptions.resources &&
                                  contentOptions.resources[3] &&
                                  contentOptions.resources[3].field[contentOptions.currentLang]}
                                <svg viewBox="0 0 14 8" aria-hidden={true}>
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numresources.selected >= 4 ? (
                            <li>
                              <a
                                href="#"
                                className="s-productDetails__additional__resources__list__link"
                              >
                                Overclocking records
                                <svg viewBox="0 0 14 8" aria-hidden={true}>
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numresources.selected >= 5 ? (
                            <li>
                              <a
                                href="#"
                                className="s-productDetails__additional__resources__list__link"
                              >
                                Kingston glossary
                                <svg viewBox="0 0 14 8" aria-hidden={true}>
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numresources.selected == 6 ? (
                            <li>
                              <a
                                href="#"
                                className="s-productDetails__additional__resources__list__link"
                              >
                                Kingston glossary
                                <svg viewBox="0 0 14 8" aria-hidden={true}>
                                  <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                                </svg>
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {contentOptions.numreviews && contentOptions.numreviews.selected > 0 ? (
                  <>
                    <span
                      className={`c-accordion__tab${
                        currentPanel == "accPanel-4" ? " c-accordion__tab--active" : ""
                      }`}
                      id="accTab-4"
                    >
                      <button
                        aria-controls="accPanel-4"
                        aria-expanded="false"
                        onClick={() => handleToggle("accPanel-4")}
                      >
                        <h2 className="s-productDetails__additional__title">
                          {contentOptions.reviews &&
                            contentOptions.reviews[0] &&
                            contentOptions.reviews[0].field[contentOptions.currentLang]}
                        </h2>
                        <svg
                          className="c-accordion__tab__icon"
                          viewBox="0 0 14 8"
                          aria-hidden={true}
                        >
                          <path d="M13.02.02a.062.062 0 00-.09 0L7.058 5.87c-.038.035-.068.039-.105.01L1.071.02a.062.062 0 00-.09 0L.02.977a.062.062 0 000 .09l6.937 6.915a.066.066 0 00.09 0l.962-.96 5.973-5.954a.065.065 0 000-.09L13.02.02z"></path>
                        </svg>
                      </button>
                    </span>
                    <div
                      className="c-accordion__panel s-productDetails__additional__reviews"
                      id="accPanel-3"
                      aria-labelledby="accTab-3"
                    >
                      <div className="c-accordion__panel__content">
                        <ul className="u-list-unstyled s-productDetails__additional__reviews__list">
                          <li>
                            <C_Card
                              className="c-card c-card--review"
                              type="review"
                              image="https://media.kingston.com/europe/atc/2021/MEA/criticreview_publogo_mea.png"
                              urlcta="#link"
                              copy={
                                contentOptions.reviews &&
                                contentOptions.reviews[1] &&
                                contentOptions.reviews[1].field[contentOptions.currentLang]
                              }
                              cta={
                                contentOptions.reviews &&
                                contentOptions.reviews[2] &&
                                contentOptions.reviews[2].field[contentOptions.currentLang]
                              }
                            />
                          </li>
                          {contentOptions.numreviews.selected >= 2 ? (
                            <li>
                              <C_Card
                                className="c-card c-card--review"
                                type="review"
                                image=""
                                urlcta="#link"
                                copy="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
                                cta="Lorem ipsum"
                              />
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numreviews.selected >= 3 ? (
                            <li>
                              <C_Card
                                className="c-card c-card--review"
                                type="review"
                                image="https://media.kingston.com/europe/atc/2020/UK/Jabba-2_UK.png"
                                urlcta="#link"
                                copy="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
                                cta=""
                              />
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numreviews.selected >= 4 ? (
                            <li>
                              <C_Card
                                className="c-card c-card--review"
                                type="review"
                                image=""
                                urlcta="#link"
                                copy="“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”"
                                cta=""
                              />
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numreviews.selected >= 5 ? (
                            <li>
                              <C_Card
                                className="c-card c-card--review"
                                type="review"
                                image="https://media.kingston.com/europe/atc/2021/MEA/criticreview_publogo_mea.png"
                                urlcta="#link"
                                copy={
                                  contentOptions.reviews &&
                                  contentOptions.reviews[1] &&
                                  contentOptions.reviews[1].field[contentOptions.currentLang]
                                }
                                cta={
                                  contentOptions.reviews &&
                                  contentOptions.reviews[2] &&
                                  contentOptions.reviews[2].field[contentOptions.currentLang]
                                }
                              />
                            </li>
                          ) : (
                            ""
                          )}
                          {contentOptions.numreviews.selected == 6 ? (
                            <li>
                              <C_Card
                                className="c-card c-card--review"
                                type="review"
                                image="https://media.kingston.com/europe/atc/2021/MEA/criticreview_publogo_mea.png"
                                urlcta="#link"
                                copy={
                                  contentOptions.reviews &&
                                  contentOptions.reviews[1] &&
                                  contentOptions.reviews[1].field[contentOptions.currentLang]
                                }
                                cta={
                                  contentOptions.reviews &&
                                  contentOptions.reviews[2] &&
                                  contentOptions.reviews[2].field[contentOptions.currentLang]
                                }
                              />
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {contentOptions.keyfeatures && contentOptions.keyfeatures.checkbox ? (
        <>
          <section className="s-keyFeature ">
            <picture className="e-picture s-keyFeature__image" id="js-picture">
              <source srcSet="/images/fpo/general/512x750.png" media="(max-width:32em)" />
              <source srcSet="/images/fpo/general/1024x512.png" media="(max-width:64em)" />
              <img src="/images/fpo/general/2048x610.png" alt="Alternate Text" />
            </picture>
            <div className="l-inner u-p0">
              <div className="s-keyFeature__content u-p">
                <article>
                  <h4>Lorem Ipsum</h4>
                  <p>Etiam sit amet nisl purus in mollis nunc sed id semper.</p>
                  <a target="_self" title="Button Title" href="#button" className="e-btn">
                    <span>Quis varius</span>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section className="s-keyFeature ">
            <picture className="e-picture s-keyFeature__image" id="js-picture">
              <source srcSet="/images/fpo/general/512x750.png" media="(max-width:32em)" />
              <source srcSet="/images/fpo/general/1024x512.png" media="(max-width:64em)" />
              <img src="/images/fpo/general/2048x610.png" alt="Alternate Text" />
            </picture>
            <div className="l-inner u-p0">
              <div className="s-keyFeature__content u-p">
                <article>
                  <h4>Lorem Ipsum</h4>
                  <p>Etiam sit amet nisl purus in mollis nunc sed id semper.</p>
                  <a target="_self" title="Button Title" href="#button" className="e-btn">
                    <span>Quis varius</span>
                  </a>
                </article>
              </div>
            </div>
          </section>
          <section className="s-keyFeature ">
            <picture className="e-picture s-keyFeature__image" id="js-picture">
              <source srcSet="/images/fpo/general/512x750.png" media="(max-width:32em)" />
              <source srcSet="/images/fpo/general/1024x512.png" media="(max-width:64em)" />
              <img src="/images/fpo/general/2048x610.png" alt="Alternate Text" />
            </picture>
            <div className="l-inner u-p0">
              <div className="s-keyFeature__content u-p">
                <article>
                  <h4>Lorem Ipsum</h4>
                  <p>Etiam sit amet nisl purus in mollis nunc sed id semper.</p>
                  <a target="_self" title="Button Title" href="#button" className="e-btn">
                    <span>Quis varius</span>
                  </a>
                </article>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}
    </>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "product-details",
  component: S_ProductDetails,
  navtxt: "Product Details",
  htmlName: "ProductDetails",
  categoryType: ["web"],
};
